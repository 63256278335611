@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background-color: #1e1e1e;
  color: #fff;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.container {
  width: 90vw;
  max-width: 1220px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px auto;
}

.section {
  background-color: #333;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  text-align: center;
  position: relative;
  flex: auto;
  margin: 15px;
}

h3 {
  color: #fff;
  margin-top: 0;
  position: absolute;
  left: 0;
  top: -15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 5px;
}

.link-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.link-container a {
  text-decoration: none;
  z-index: 1;
}

.link {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #555;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 0.9rem;
  font-weight: 600;
  overflow: hidden;
}

.link:hover {
  transform: scale(1.1);
}

.link p {
  margin: 0;
  padding: 0;
}

.link-icon {
  width: 60%;
  height: 60%;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link img {
  width: 100%;
}

.link svg {
  width: 100%;
  height: 100%;
}

.toggle-container {
  display: flex;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}

.toggle-label {
  margin-right: 5px; /* Adjust margin as needed */
  cursor: pointer;
}

.toggle {
  display: none;
}

.toggle-icon {
  font-size: 2em; /* Adjust font size as needed */
  color: #fff; /* Icon color */
  cursor: pointer;
}

/* Media query for mobile */
@media (max-width: 600px) {
  .section {
    max-width: 100%;
  }
  .container {
    width: auto;
  }
}

@media (max-width: 1000px) {
  .toggle-container {
    position: relative;
    top: 0;
    left: 0;
  }
}
